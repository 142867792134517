import Axios from 'axios';

const apiUrl = process.env.REACT_APP_CUSTOMERVIEW_API;
const jobBoardApi = process.env.REACT_APP_JOB_BOARD_API;
const feedbackApi = process.env.REACT_APP_FEEDBACK_API;
const eStaffingCvApi = process.env.REACT_APP_ESTAFFING_CV_API;
const catalogApiUrl = process.env.REACT_APP_CATALOG_API;

const mainContext = 'user';
const module = 'talents';

export const getTalents = (userId, statusId, filter, page=0, size=10) => {
    const filterParam = filter ? `&filter=${filter}` : '';
    return Axios.get(`${apiUrl}${mainContext}/${userId}/${module}/pageable?statusId=${statusId}&page=${page}&size=${size}&sort=firstName&direction=ASC${filterParam}`)
};

export const getTalent = talentId => {
    return Axios.get(`${jobBoardApi}talent/${talentId}`);
}

export const getFeedbacks = (userId, talentId) => {
    return Axios.get(`${feedbackApi}${mainContext}/${userId}/talent/${talentId}/feedback/list`)
}

export const saveFeedback = (userId, talentId, feedback) => {
    return Axios.post(`${feedbackApi}${mainContext}/${userId}/talent/${talentId}/feedback/save`, feedback)
}

export const downloadCv = talentId => {
    return Axios.get(`${eStaffingCvApi}talent/${talentId}/downloadcv`, {
        responseType: 'blob'
    })
}
export const getSkills = talentId => {
    return Axios.get(`${jobBoardApi}talent/${talentId}/skills/`);
}

export const getSkillsCatalog = (talentId, page ,size) => {
    return Axios.get(`${catalogApiUrl}feedback/${talentId}/skills/?page=${page}&size=${size}&sort=name&direction=ASC`)
};