import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../TalentMain";
import styles from '../../talentInfo.module.scss';
import { MONTHS, selectEmtpy, yearsHardCode, yearsOrder } from "../../../../core/hardcode";
import { defaultStringEmpty, serializeSelectValue, sorterDate, unsearializaDateString } from "../../../../core/helper";
import CoursesNotFoundImg from '../../../../../assets/img/courses_not_found.png';

export const Academics = () => {

    const appContext = useContext(AppContext);

    const [academics, setAcademics] = useState([]);
    const [courses, setCourses] = useState([]);

    const setSelectDates = academic => {
        const academicTmp = { ...academic };
        if ( academic.endDate ) {
            const yearHardCode = yearsHardCode(yearsOrder.full);
            const endDate = unsearializaDateString(academicTmp.endDate);
            const fTerMes = MONTHS.find( m => m.id === endDate.monthId )
            const fTerAnio = yearHardCode.find( y => y.optionName === endDate.yearDesc)
            // console.log(academic, MONTHS, yearHardCode, fTerMes, fTerAnio);
            academicTmp.fTerMes = serializeSelectValue(fTerMes);
            academicTmp.fTerAnio = serializeSelectValue(fTerAnio);
            academicTmp.stillStudy = false;
        } else {
            academicTmp.fTerMes = selectEmtpy;
            academicTmp.fTerAnio = selectEmtpy;
            academicTmp.stillStudy = true;
        }

        if ( academic.startDate ) {
            const yearHardCode = yearsHardCode(yearsOrder.full);
            const startDate = unsearializaDateString(academicTmp.startDate);
            const fIniMes = MONTHS.find( m => m.id === startDate.monthId )
            const fIniAnio = yearHardCode.find( y => y.optionName === startDate.yearDesc)
            academicTmp.fIniMes = serializeSelectValue(fIniMes);
            academicTmp.fIniAnio = serializeSelectValue(fIniAnio);
        } else {
            academicTmp.fIniMes = selectEmtpy;
            academicTmp.fIniAnio = selectEmtpy;
        }

        if ( academic.validityDate ) {
            const yearHardCode = yearsHardCode(yearsOrder.full);
            const validityDate = unsearializaDateString(academicTmp.validityDate);
            const fValMes = MONTHS.find( m => m.id === validityDate.monthId )
            const fValAnio = yearHardCode.find( y => y.optionName === validityDate.yearDesc)
            academicTmp.fValMes = serializeSelectValue(fValMes);
            academicTmp.fValAnio = serializeSelectValue(fValAnio);
            academicTmp.periodVal = `${academicTmp.fValMes.description} ${academicTmp.fValAnio.description}`;
        } else {
            academicTmp.fValMes = selectEmtpy;
            academicTmp.fValAnio = selectEmtpy;
            academicTmp.periodVal = 'Sin vigencia';
        }

        return academicTmp;
    }

    const buildPeriodStr = academic => {
        const endDateStr = academic.stillWork || academic.fTerMes.description === '' ? 'En curso' : `${academic.fTerMes.description} ${academic.fTerAnio.description}`;
        const startDateStr = academic.fIniMes.description === '' ? 'Sin especificar' : `${academic.fIniMes.description} ${academic.fIniAnio.description}`;
        return `${startDateStr} - ${endDateStr}`;
    }

    const parseAcademics = () => {
        let academicsTmp = [];
        appContext.talent.educations?.map( education => {
            const weWithDates = setSelectDates(education);
            weWithDates.period = buildPeriodStr(weWithDates);
            academicsTmp.push(weWithDates);
        });
        setAcademics(academicsTmp.sort((a, b) => sorterDate(a.fIniAnio.id, a.fIniMes.id, b.fIniAnio.id , b.fIniMes.id, yearsOrder.asc)));
    }

    const parseCourses = () => {
        let coursesTmp = [];
        appContext.talent.courses?.map( course => {
            const weWithDates = setSelectDates(course);
            const periodBuild = buildPeriodStr(weWithDates);
            weWithDates.period = periodBuild.startsWith('Sin especificar') ? 
                weWithDates.stillWork || weWithDates.fTerMes.description === '' ? 'En curso' : `${weWithDates.fTerMes.description} ${weWithDates.fTerAnio.description}` 
                : periodBuild;
            coursesTmp.push(weWithDates);
        });
        setCourses(coursesTmp.sort((a, b) => sorterDate(a.fIniAnio.id, a.fIniMes.id, b.fIniAnio.id , b.fIniMes.id, yearsOrder.asc)));
    }

    useEffect(() => {
        parseAcademics();
        parseCourses();
    }, [])
    

    const renderEducation = () => academics.map((e, index) => (
        <div key={ index } className={ styles.courses }>
            <span className={ styles.title }>{e.name} - {e.school}</span>
            <div>
                <span className={ styles.p_title }>Periodo:</span> {e.period}
            </div>
        </div>
    ));

    const renderCourses = () => courses.map( (c, index) => (
        <div key={ index } className={ styles.courses }>
            <span className={ styles.title }>{c.name} - {defaultStringEmpty(c.school)}</span>
            <div>
                { c.certificateNumber && <p><span className={ styles.p_title }>N&uacute;mero de certificado:</span> { c.certificateNumber }</p> }
                <p><span className={ styles.p_title }>{ c.period.includes(' - ') ? 'Periodo:' : 'Fecha:'}</span> {c.period}</p>
                { c.periodVal && c.periodVal !== 'Sin vigencia' && <p><span className={ styles.p_title }>Fecha de vigencia:</span> {c.periodVal}</p> }
            </div>
        </div>
    ))

    const renderDetailSection = (title, child) => (
        <div className={ `${styles.section} ${styles.padding_x_10}` }>
            <div className={ styles.title }>
                <div className={ styles.title_bar } />
                <h2>{ title }</h2>
            </div>
            <div className={ styles.content }>
                { child ? child : <p>Información no porporcionada.</p> }
            </div>
        </div>
    )

    const renderNotFound = () => (
        <div className={ styles.not_found }>
            <div>
                <p className={ styles.h2 }>Sin registro de informaci&oacute;n</p>
                <p className={ styles.h3 }>{appContext.talent.firstName} a&uacute;n no tiene registrada su formaci&oacute;n acad&eacute;mica.</p>
            </div>
            <img src={ CoursesNotFoundImg } />
        </div>
    )

    const renderResumeMain = () => {
        const hasEducation = academics.length > 0;
        const hasCourses = courses.length > 0;
        return ( !hasEducation && !hasCourses) ? renderNotFound() : (
            <div className={ styles.sections }>
                { renderDetailSection( 'Educación', hasEducation ? renderEducation() : null ) }
                { renderDetailSection( 'Cursos y Certificaciones', hasCourses ? renderCourses() : null ) }
            </div>
        )
    };

    return renderResumeMain();
}
