import PropTypes from 'prop-types';
import styles from './pagination.module.scss';
import arrowBackDis from '../../../assets/icons/arrow_back_dis.svg';
import arrowBackEna from '../../../assets/icons/arrow_back_ena.svg';
import arrowForwDis from '../../../assets/icons/arrow_forw_dis.svg';
import arrowForwEna from '../../../assets/icons/arrow_forw_ena.svg';

export const Pagination = ({
    totalElements,
    currentPage,
    totalPages,
    onClickBackPage,
    onClickForwardPage
}) => {    
    const cP = totalElements > 0 ? 1 : 0;
    const isBackDis = currentPage === 0;
    const isForwDis = totalPages === 0 || currentPage + cP === totalPages;

    const imgBackButton = isBackDis ? arrowBackDis : arrowBackEna;
    const imgForwardButton = isForwDis ? arrowForwDis : arrowForwEna;
    const cssBackButton = isBackDis ? styles.button_disabled : styles.button_enabled;
    const cssForwardButton = isForwDis ? styles.button_disabled : styles.button_enabled;
    
    return (
        <div className={ styles.paginator }>
            <div className={ `${ styles.button } ${ cssBackButton }` } onClick={ () => onClickBackPage() }><img src={ imgBackButton } /></div>
            <div className={ styles.label }>{ `Página ${ currentPage + cP } de ${ totalPages }` }</div>
            <div className={ `${ styles.button } ${ cssForwardButton }` } onClick={ () => onClickForwardPage(cP) }><img src={ imgForwardButton } /></div>
        </div>
    )
}

Pagination.propTypes = {
    totalElements: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    onClickBackPage: PropTypes.func.isRequired,
    onClickForwardPage: PropTypes.func.isRequired
}