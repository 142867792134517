import { decryptString, encryptString } from "./crypto";
import { yearsOrder } from "./hardcode";

export const userData = () => {
    const datals = localStorage.getItem('userInfo');
    return JSON.parse(datals);
};

export const decrypt = params => {
    const paramsSanitized = params.replaceAll(' ', '+').replaceAll(/["]/g, '');

    // ===== Data Template ==============
    // {"userId":500,"email":"oziel.naranjo@mystratis.com","roleId":1000000003,"name":"Oziel Naranjo Marquez","clientId":1000000003}
    // ===== Token for user 9 ==============
    // const workEncrypt = encryptString('{"userId":9,"email":"oziel.naranjo@mystratis.com","roleId":1000000003,"name":"Oziel Naranjo","clientId":14}');
    // console.log(workEncrypt); // yiJ39FxntYeVuUBtKZex4jXLgIjkbRYjd8ohAxHjpahvmVrzYj7447NBgwj/xtQ8gGManaXxhvNxjC/9esR/KoA3UjWXryYDHrgF1HCptxvbU+zJMfxCHKwZAfOVW1jUyL9YrulBTvsZeU8C/QnIoA==
    // ===== Token for user 282 Edgar Saucedo ==============
    // const workEncrypt = encryptString('{"userId":282,"email":"edgar.saucedo@mystratis.com","roleId":1000000003,"name":"Egar Saucedo","clientId":1000000007}');
    // console.log(workEncrypt); // e9rPa8chXyxa+X+oSomTod/NmZEWh8p6xsdnKMJR9p5rhUFUecER8vbmhCpgi9KUV856lJ7BEzuqFuVe7rPaSkSykDf7HmbskqgVxyLidhdi9952aG0WGAOTJ9Q/hx1AG0jiLWHqQ0EizyG0R+0561YQLi0xheILnWQPV001YCE=
    // ===== Token for user 91 Oscar Hernandez ==============
    // const workEncrypt = encryptString('{"userId":91,"email":"oscar.hernandez@mystratis.com","roleId":1000000003,"name":"Oscar Hernandez","clientId":1000000001,"clientName":"QA-Stratis Edgar"}');
    // console.log(workEncrypt); // ZQ2/lWEH0H0SEbbYbMDzw6hDJQ4O5iIlsMrZMVvtdzMlHjA9yYqDxwf8dsuYXuvkNilWpXiuNRjsGxQio9fFk1sLZLKC3Jx8kS5X7CUoHREWNatt0ltdURA8pwO8w5H3djBuZJ5l3p56POY70G9GnjaGcU5T97ilxngG+CrjwSW6fDxHPNVaY3m74dAdauFzQLhibVWH3ZYkGmK8sYuYxA==
    //===== Token for user 360 marlon.canales1@mystratis.com ==============
    //const workEncrypt = encryptString('{"userId":360,"email":"marlon.canales1@mystratis.com","roleId":1000000002,"name":"Marlon Canales Pruebas Talentos","clientId":1000000001,"clientName":"QA-Stratis Edgar"}');
    //console.log(workEncrypt); // f5qTr2S39DaKwG1Q3cWHy/cp/xD4edd1Ztimw6LPjCxPgK3oPxELL2NT6OWVTrqhVEo/OUtCOf6uWaiZVhWXwIbwjVsPL9NXFEQeROUvc/stRdt6+lCwjhdH678T8e0/I50nfIgaxlnkFOjrdY10GBqKSqnKa9mN0Ht3AJQYHDScVyBjEIy3xgAXMnMMlcT3US9G10i8/BnpbBCav0jXq9UnNWw3SuIhFekilus82dg=
    //===== Token for user 353 marlon.canales1@mystratis.com ==============
    // const workEncrypt = encryptString('{"userId":353,"email":"marlon.canales@mystratis.com","roleId":1000000003,"name":"marlon.canales@mystratis.com","clientId":18,"clientName":"STRATIS"}');
    // console.log(workEncrypt); // J9Kcks9RY0Tejyu4FnvIs7vfrggY8dzmaJEno0BNQEVZ4gTJPLUN2/VLf1nz/+mGfZqCovg6E+cEeevKP3DDVPngPPL9rd295caCVx5BtagUVWGOSGCoZNjpZ61LYWbqsIsoU8BnbRgm7o8NvbIoLFcR5tNDAY9uTGzK038TheixKOwjaVpk5CNicE9UXLs5IkZ52FwJlkOZGzepoiT6sw==

    const decrypted = decryptString(paramsSanitized);
    try {
        return JSON.parse(decrypted);
    } catch (error) {
        return null;
    }
}

export const defaultStringEmpty = value => {
    return value && value !== '' ? value : 'Sin especificar';
}

export const unsearializaDateString = (dateStr) => {
    const dateArray = dateStr.split('-');
    const year = dateArray[0];
    const month = Number(dateArray[1]);
    const day = dateArray[2];
    return {
        'day': day,
        monthId: month,
        yearDesc: year
    }
}

export const serializeSelectValue = ({ id, optionName }) => ({
    id: id,
    description: optionName
})

export const sorterDate = (yearId1, monthId1, yearId2, monthId2, order) => {
    let result = 0;
    const yearPos = order === yearsOrder.desc ? yearId1 > yearId2 : yearId1 < yearId2;
    const yearMNeg = order === yearsOrder.desc ? yearId1 < yearId2 : yearId1 > yearId2;
    if (yearPos) {
        result = 1
    } else if (yearMNeg) {
        result = -1
    }
    if( result === 0 ) {
        if (monthId1 < monthId2) {
            result = 1
        } else if (monthId1 > monthId2) {
            result = -1
        }
    }
    // console.log('sorter date', yearId1, monthId1, yearId2, monthId2, result);
    return result;
}
export const sorterAlphabetic = (a, b) => {
    const textA = a.toUpperCase();
    const textB = b.toUpperCase();
    const result = (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    return result;
}

export const borderError = error => error ? { borderColor: 'var(--color-error)' } : {}

export const stringCutter = (value, maxLength) => value.length > maxLength ? `${value.substring(0, maxLength)}...` : value