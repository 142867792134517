import { useContext } from 'react';
import PropTypes from 'prop-types';
import styles from './evaluation.module.scss';
import { AppContext } from '../../../TalentMain';
import { EvaluationSkill } from './EvaluationSkill';
import { TextArea } from '../../../../../components/textarea/TextArea';
import { SKILLS } from '../../../../../core/hardcode';

export const EvaluationType = ({
    config,
    commentValue,
    onChange,
    commentError,
    commentFocus
}) => {

    const appContext = useContext(AppContext);

    return (
        <div className={ styles.form }>
            <span className={ styles.title }>{ config.title }</span>
            <div className={ styles.separator } />
            <div className={ styles.paragraph }>
                <p><b>{ `Basándote en las habilidades de ${appContext.talent.firstName}, ¿cuáles consideras que son sus ${config.questionSuffix}?` }</b></p>
                <p>Puedes seleccionar las <b>siguientes sugerencias basadas en su perfil</b>, las cuales est&aacute;n divididas en Competencias T&eacute;cnicas, Competencias Personales y Software, o bien, agregar una nueva.</p>
            </div>
            <div className={ styles.skill_sections }>
                { SKILLS.filter( s => s.categoryId !== 1001100003 ).map( (s, index) => {
                    return <EvaluationSkill key={ index }
                        name={ config.name }
                        categoryId={ s.categoryId }
                        labelNewButton={ s.labelNewButton }
                        placeholderNewSkill={ s.placeholderNewSkill }
                        onChange={ onChange }
                    />
                } ) }
            </div>
            <TextArea
                cssSubtitle={ styles.subtitle }
                cssCounter={ styles.subtitle_left }
                name={ config.comment.name }
                label={ config.comment.label }
                sublabel={ config.comment.sublabel }
                maxLength={ config.comment.maxLength }
                value={ commentValue }
                onChange={ onChange }
                error={ commentError ? commentError : '' }
                focus={ commentFocus }
                placeholder={ config.placeholder }
            />
        </div>
    )
}

EvaluationType.propTypes = {
    config: PropTypes.object.isRequired,
    commentValue: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    commentError: PropTypes.string,
    commentFocus: PropTypes.bool
}

EvaluationType.defaultProps = {
    commentFocus: false
}