import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { decrypt } from '../core/helper';

export const ValidateParam = () => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
      const params = searchParams.get("params");
      if( !params ) {
        navigate('/invalid-data');
      } else {
        const userInfo = decrypt(params);
        if(userInfo) {
          localStorage.setItem('userInfo', JSON.stringify(userInfo));
          navigate('/my-services');
        } else {
          navigate('/invalid-data');
        }
      }
    }, [])
}
