import styles from './evaluation.module.scss';
import notificationStyles from '../../../../../components/notification/notification.module.scss';
import {ReactComponent as BackArrowIcon } from '../../../../../../assets/icons/back_arrow.svg'
import { useContext, useRef, useState } from 'react';
import { AppContext } from '../../../TalentMain';
import { EvaluationType } from './EvaluationType';
import { EvaluationStars } from './EvaluationStars';
import { userData } from '../../../../../core/helper';
import { CloseEvaluationModal } from './CloseEvaluationModal';
import { Loading } from '../../../../../components/loading/Loading';
import { saveFeedback } from '../../../../../api/talentInfoClient';

export const TalentNewEvaluation = () => {

    const rateRef = useRef();

    const appContext = useContext(AppContext);

    const config = {
        strengths: {
            title: 'Fortalezas',
            questionSuffix: 'fortalezas',
            comment: {
                name: 'strengthsComment',
                label: 'Detalle sobre Fortalezas (opcional)',
                sublabel: <>¿Deseas agregar alg&uacute;n comentario adicional sobre las habilidades de {appContext.talent.firstName}? <b>Considera que esta informaci&oacute;n es de gran importancia para su crecimiento y desempe&ntilde;o profesional, ya que representa una valiosa fuente de motivaci&oacute;n.</b></>,
                maxLength: 1500
            },
            name: 'strengths'
        },
        opportunities: {
            title: 'Áreas de Oportunidad',
            questionSuffix: 'oportunidades de mejora',
            comment: {
                name: 'opportunitiesComment',
                label: 'Detalle sobre Áreas de Oportunidad (requerido)',
                sublabel: <>Describe con el mayor detalle posible los casos en los que hayas identificado las &aacute;reas de oportunidad que seleccionaste. <b>Esta informaci&oacute;n ayudar&aacute; a los Coaches de formaci&oacute;n y desarrollo de Talento a elaborar acciones de mejora que permitan transformar las &aacute;reas de oportunidad en nuevas fortalezas.</b></>,
                maxLength: 1500,
                msgError: 'Para poder continuar, incluye el detalle requerido sobre las áreas de oportunidad que seleccionaste.'
            },
            name: 'opportunities',
            placeholder: 'Puedes tomar como ejemplo la siguiente información.\n\nOrientación al detalle: cuando levanta los requerimientos de un proyecto, constantemente suele faltarle información importante sobre las necesidades de su cliente interno, causando demoras y retrabajos en el proceso.'
        }
    }

    const feedbackEmpty = {
        rate: 0,
        strengthsComment: '' ,
        opportunitiesComment: '',
        strengths: [],
        opportunities: []
    };

    const [feedback, setFeedback] = useState(feedbackEmpty);
    const [opportunitiesCommentError, setOpportunitiesCommentError] = useState('');
    const [opportunitiesCommentFocus, setOpportunitiesCommentFocus] = useState(false);

    const onChangeFeedback = (property, value, categoryId) => {
        // console.log('onChange Feedback', property, value, categoryId);
        appContext.setNotificationData({notificationStyle: notificationStyles.notification_wrapper});
        if( property === config.strengths.name ) {
            const skills = [ ...feedback.strengths.filter( s => s.categoryId !== categoryId ) ];
            setFeedback({ ...feedback, [property]: [ ...skills, ...value ]});
        } else if ( property === config.opportunities.name ) {
            const skills = [ ...feedback.opportunities.filter( s => s.categoryId !== categoryId ) ];
            setFeedback({ ...feedback, [property]: [ ...skills, ...value ]});
        } else {
            setFeedback({ ...feedback, [property]: value});
        }
        if( property === config.opportunities.comment.name && value.length > 0 && opportunitiesCommentError !== '' ) {
            setOpportunitiesCommentError('');
        }
    }

    const onClickBack = () => {
        if ( JSON.stringify(feedback) !== JSON.stringify(feedbackEmpty) ) {
            appContext.setModalContent( <CloseEvaluationModal /> )
            appContext.setModalFlag(true)
        } else {
            appContext.setSteper(1);
        }
    }

    const renderBackButton = () => {
        return (
            <div className={ styles.backbutton } onClick = { () => onClickBack() }>
                <BackArrowIcon /><span>Salir de evaluaci&oacute;n</span>
            </div>
        )
    }

    const postSave = () => {
        appContext.setModalContent( <Loading label={ 'Enviando evaluación' } /> );
        appContext.setModalFlag(true);
        saveFeedback((userData()).userId, appContext.talent.id, feedback).then( response => {
            appContext.setModalFlag(false);
            if ( response.status === 200 ) {
                window.scrollTo(0, 0);
                const msgNotification = `Gracias por evaluar el desempeño de ${appContext.talent.firstName}.`;
                appContext.displayNotification(msgNotification, false, 115, true, 3000);
                appContext.setSteper(1);
            } else {
                //appContext.displayNotification(msgNotification, true, 160);
            }
        }).catch( error => {
            console.log(error);
            appContext.setModalFlag(false);
            const detail = error.response.data && error.response.data.msg ? ` [${error.response.data.msg}]` : '';
            appContext.displayNotification(`${error.message}${detail}`, true, 160, true);
        })
    }

    const onClickSave = () => {
        setOpportunitiesCommentFocus(false);
        let msgNotification = 'Para poder continuar, selecciona al menos una fortaleza o área de oportunidad.';
        if ( feedback.strengths.length === 0 && feedback.opportunities.length === 0 ) {
            window.scrollTo(0, 0);
            appContext.displayNotification(msgNotification, true, 160);
        } else if( feedback.opportunities.length > 0 && feedback.opportunities === '' ) {
            const rateRect = rateRef.current.getBoundingClientRect();
            const positionScroll = window.screen.height <= 900 ? rateRect.top + 195 : rateRect.top - 165;
            setOpportunitiesCommentError(config.opportunities.comment.msgError)
            window.scrollTo(0, window.scrollY - positionScroll);
            setOpportunitiesCommentFocus(true);
        } else if ( feedback.rate === 0 ) {
            msgNotification = `Para poder continuar, selecciona la cantidad de estrellas con las que deseas evaluar a ${appContext.talent.firstName}.`;
            const rateRect = rateRef.current.getBoundingClientRect();
            appContext.displayNotification(msgNotification, true, window.scrollY + rateRect.top - 25);
        } else {
            postSave();
        }
    }

    return (
        <div className={ styles.wrapper }>
            { renderBackButton() }
            {
                <EvaluationType 
                    config={ config.strengths }
                    commentValue={ feedback.strengthsComment }
                    onChange={ onChangeFeedback }
                />
            }
            {
                <EvaluationType
                    config={ config.opportunities }
                    commentValue={ feedback.opportunitiesComment }
                    onChange={ onChangeFeedback }
                    commentError={ opportunitiesCommentError }
                    commentFocus={ opportunitiesCommentFocus }
                />
            }
            <div ref={ rateRef }>
                <EvaluationStars
                    name='rate'
                    value={ feedback.rate }
                    onChange={ onChangeFeedback }
                />
            </div>
            <div className={ styles.button_container }>
                <div className={ styles.button } onClick={ () => onClickSave() }>Enviar evaluación</div>
            </div>
        </div>
    )
}
