import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../TalentMain";
import styles from '../../talentInfo.module.scss';
import { MONTHS, selectEmtpy, yearsHardCode, yearsOrder } from "../../../../core/hardcode";
import WorkExperiencesNotFoundImg from '../../../../../assets/img/work_experience_not_found.png';
import { defaultStringEmpty, serializeSelectValue, sorterDate, unsearializaDateString } from "../../../../core/helper";

export const WorkExperiences = () => {
    const appContext = useContext(AppContext);
    
    const [workExperiences, setWorkExperiences] = useState([]);

    const orderWorkExperiences = workExperiences => workExperiences.sort((a, b) => sorterDate(
        a.stillWork ? a.fiAnio.id : a.ftAnio.id, 
        a.stillWork ? a.fiMes.id : a.ftMes.id, 
        a.stillWork ? b.fiAnio.id : b.ftAnio.id, 
        a.stillWork ? b.fiMes.id : b.ftMes.id,
        yearsOrder.desc
    ));

    const setStartAndEndDates = (obj, rangeYear1, rangeYear2) => {
        const element = { ...obj };
        if( element.startDate ) {
            const startDate = unsearializaDateString(element.startDate);
            const fiMes = MONTHS.find( m => m.id === startDate.monthId )
            const fiAnio = rangeYear1.find( y => y.optionName === startDate.yearDesc)
            element.fiMes = serializeSelectValue(fiMes);
            element.fiAnio = serializeSelectValue(fiAnio);
        } else {
            element.fiMes = selectEmtpy;
            element.fiAnio = selectEmtpy;
        }
        if ( element.endDate ) {
            const endDate = unsearializaDateString(element.endDate);
            const ftMes = MONTHS.find( m => m.id === endDate.monthId )
            const ftAnio = rangeYear2.find( y => y.optionName === endDate.yearDesc)
            element.ftMes = serializeSelectValue(ftMes);
            element.ftAnio = serializeSelectValue(ftAnio);
            element.stillWork = false;
        } else {
            element.ftMes = selectEmtpy;
            element.ftAnio = selectEmtpy;
            element.stillWork = true;
        }
        return element;
    }

    const setSelectDates = we => {
        const yearHardCode = yearsHardCode(yearsOrder.desc);
        return setStartAndEndDates(we, yearHardCode, yearHardCode);
    }

    const buildPeriodStr = workExperience => {
        const endDateStr = workExperience.stillWork || workExperience.ftMes.description === '' ? 'Actualidad' : `${workExperience.ftMes.description} ${workExperience.ftAnio.description}`;
        const startDateStr = workExperience.fiMes.description === '' ? 'Sin especificar' : `${workExperience.fiMes.description} ${workExperience.fiAnio.description}`;
        return (startDateStr === 'Sin especificar' && endDateStr === 'Actualidad') ? 'Sin especificar' : `${startDateStr} - ${endDateStr}`;
    }

    useEffect(() => {
        let workExperiencesTmp = [];
        appContext.talent.previousJobs.map( workExperience => {
            const weWithDates = setSelectDates(workExperience);
            weWithDates.period = buildPeriodStr(weWithDates);
            workExperiencesTmp.push(weWithDates);
        });
        const workExperiencesCurrents = workExperiencesTmp.filter( we => we.stillWork || we.ftMes.description === '' );
        const workExperiencesLast = workExperiencesTmp.filter( we => !we.stillWork && we.ftMes.description !== '' );
        workExperiencesTmp = [ ...orderWorkExperiences(workExperiencesCurrents) ]
        workExperiencesTmp = [ ...workExperiencesTmp, ...orderWorkExperiences(workExperiencesLast) ]
        setWorkExperiences(workExperiencesTmp);
    }, [])
    

    const renderWorkExperiences = we => (
        <div className={ styles.work_experience }>
            <div><span className={ styles.p_title }>Periodo:</span> { we.period }</div>
            <p>{we.description}</p>
        </div>
    );

    const renderDetailSection = () => workExperiences.map((we, index) => (
        <div key={index} className={ `${styles.section} ${styles.padding_x_10}` }>
            <div className={ styles.title }>
                <div className={ styles.title_bar } />
                <h2>{we.position} - {defaultStringEmpty(we.employer)} - Sector: { defaultStringEmpty(we.sectorName) }</h2>
            </div>
            <div className={ styles.content }>
                { renderWorkExperiences(we) }
            </div>
        </div>
    ))

    const renderNotFound = () => (
        <div className={ styles.not_found }>
            <div>
                <p className={ styles.h2 }>Sin registro de informaci&oacute;n</p>
                <p className={ styles.h3 }>{appContext.talent.firstName} a&uacute;n no tiene registrada su experiencia laboral.</p>
            </div>
            <img src={ WorkExperiencesNotFoundImg } />
        </div>
    )

    const renderMain = () => {
        const hasWorkExperiences = workExperiences.length > 0;
        return hasWorkExperiences ? (
            <div className={ styles.sections }>
                { renderDetailSection() }
            </div>
        ) : renderNotFound()
    };

    return renderMain();
}
