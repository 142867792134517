import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './evaluation.module.scss';
import { AppContext } from '../../../TalentMain';
import { SKILLS } from '../../../../../core/hardcode';
import {ReactComponent as NewIcon } from '../../../../../../assets/icons/new_icon.svg';
import {ReactComponent as SelectedIcon} from '../../../../../../assets/icons/item_selected.svg';
// import {ReactComponent as RemoveIcon} from '../../../../../../assets/icons/item_remove.svg';
import { TextButton } from '../../../../../components/textbutton/TextButton';
import { sorterAlphabetic, stringCutter, userData } from '../../../../../core/helper';

const type = {
    suggestion: 1,
    custom: 2
}

const maxLength = 50;

export const EvaluationSkill = ({
    name,
    categoryId,
    labelNewButton,
    placeholderNewSkill,
    onChange
}) => {

    const skillBlank = {
        name: '',
        ['categoryId']: categoryId,
        type: type.custom,
        selected: false
    };

    const appContext = useContext(AppContext);
    const skill = SKILLS.find( c => c.categoryId === categoryId);
    const [skills, setSkills] = useState([]);
    const [addingNew, setAddingNew] = useState(false);
    const [newSkill, setNewSkill] = useState();
    
    const skillsFilterForRequest = skills => {
        onChange(name, skills.filter( s => s.selected || s.deleted ), categoryId);
    }

    useEffect(() => {
        const skillsTemp = [];
        let counter = 1
        appContext.skillsSuggestions.filter( ss => ss.categoryId === categoryId ).map( s => {
            skillsTemp.push( { ...s, type: type.suggestion, index: counter, deleted: false} )
            counter++;
        })
        const skillsSorter = skillsTemp.sort((a,b) => sorterAlphabetic(a.name, b.name));
        setSkills(skillsSorter);
    }, [])
    

    const onClickNew = () => {
        setAddingNew(true);
        setNewSkill(skillBlank);
    }

    const onChangeNew = ({ target }) => {
        // console.log('onChange Skill new', property, value);
        setNewSkill({ ...newSkill, ['name']: target.value, index: skills.length + 1});
    }

    const onClickAdd = () => {
        const skillsTmp = [ ...skills ];
        skillsTmp.push( { ...newSkill, selected: true });
        setSkills(skillsTmp.sort((a,b) => sorterAlphabetic(a.name, b.name)));
        skillsFilterForRequest(skillsTmp);
        setNewSkill(skillBlank);
        //setAddingNew(false);
    }

    const renderNewButton = () => {
        return addingNew ? 
        <div className={ styles.width_40 }>
            <TextButton
            label={ 'Agregar' }
            value={ newSkill.name }
            placeholder={ placeholderNewSkill }
            maxLength={ maxLength }
            onChange={ onChangeNew }
            onClick= { onClickAdd }
        />
        </div>
        :
        <div className={ `${styles.backbutton} ${styles.new_button}` } onClick = { () => onClickNew() }>
            <NewIcon /><span>{ labelNewButton }</span>
        </div>
    }

    const onClickSelectSkill = index => {
        const i = skills.findIndex( s => s.index === index );
        const skillsTmp = [ ...skills ];
        skillsTmp[i].selected = !skillsTmp[i].selected
        setSkills(skillsTmp);
        skillsFilterForRequest(skillsTmp);
    }

    // const onClickRemoveSkill = index => {
    //     /*
    //      * Se tuvo que agregar en timeout 
    //      * Por que el evento choca con onClickSelectSkill
    //      * Esto hace que ambos eventos se ejecuten en paralelo
    //      * Haciendo que el setSkills del select
    //      * vuelva a colocar la lista completa
    //      */
    //     setTimeout(() => {
    //         const i = skills.findIndex( s => s.index === index );
    //         const skillsTmp = [ ...skills ];
    //         skillsTmp[i].deleted = true;
    //         skillsTmp[i].selected = false;
    //         setSkills(skillsTmp);
    //         skillsFilterForRequest(skillsTmp);
    //     }, 10);
    // }

    const renderSkills = () => skills.filter( s => !s.deleted ).map(s => (
        <div key={ s.index } 
            className={ `${styles.pill} ${ s.selected /*|| s.type === type.custom*/ ? styles.selected : styles.unselected }` }
            onClick={ () => onClickSelectSkill(s.index) } >
            { s.selected && <SelectedIcon width={ 21 } height={ 21 } /> }
            { stringCutter(s.name, maxLength) }
            { /* REMOVED BY Definition in Abril 17,2024 s.type === type.custom && <RemoveIcon onClick={ () => onClickRemoveSkill( s.index ) } /> */ }
        </div>
    ))

    return (
        <div className={ styles.skill_sections_item }>
            <span className={ styles.skill_sections_item_title }>{ skill.title ? skill.title : skill.name }</span>
            <div className={ styles.skill_sections_item_pills }>
                { renderSkills() }
            </div>
            { renderNewButton() }
        </div>
    )
}

EvaluationSkill.propTypes = {
    name: PropTypes.string.isRequired,
    categoryId: PropTypes.number.isRequired,
    labelNewButton: PropTypes.string.isRequired,
    placeholderNewSkill: PropTypes.string.isRequired,
    suggestions: PropTypes.array,
    onChange: PropTypes.func.isRequired
}