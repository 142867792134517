import styles from '../pages/talents/talentInfo.module.scss';
import modalStyles from '../components/modal/modal_base.module.scss';
import headerStyles from '../components/header/header.module.scss';
import imageError from '../../assets/img/image_error.png';
import { saveLog } from '../api/logClient';

export const ErrorFallback = () => {

    return (
        <div className={ modalStyles.modal_wrapper }>
            <div className={ styles.header_fixed }>
                <div className={ headerStyles.header }>
                    <div>
                        <a href="https://www.mystratis.com" target="_blank">
                            <img src="https://www.mystratis.com/wp-content/themes/stratis/images/logo.svg" alt="Stratis" />
                        </a>
                    </div>
                </div>
            </div>
            <div className={ styles.error_boundary }>
                <label className={ styles.font }>Error al cargar la p&aacute;gina</label>
                <label className={ `${styles.font} ${styles.info}` }>Int&eacute;ntalo de nuevo recargando tu navegador o contacta directamente</label>
                <label className={ `${styles.font} ${styles.info}` }>al equipo de soporte al correo: <b className={ `${styles.font} ${styles.info} ${styles.email}` }>support@mystratis.com</b></label>
                <img src={ imageError } alt='' />
            </div>
        </div>
    )
}


export const logError = (error, { componentStack }) => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const bodyRequest = {
        "errorCode": error.name,
        "errorMessage": error.message,
        "errorDetails": error.stack,
        "reportedBy": userInfo.email,
    }
    saveLog(bodyRequest).then(response => {
        console.log(response);
    }).catch(error => {
        console.log(error);
    })
}
