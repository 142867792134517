import styles from './notification.module.scss';
import closeIcon from "../../../assets/icons/close_icon.svg";
import { useContext } from 'react';
import { AppContext } from '../../pages/talents/TalentMain';

const Notification = (props) => {

    const appContext = useContext(AppContext);

    return(
        <div className={ styles.notification } 
            style={ { display: props.data.icon ? 'block' : 'none', top: props.data.top } }>
            <div className={ props.data.notificationStyle }>
                <div className={ styles.icon_message }>
                    <img src={ props.data.icon } alt="" />
                    <p>{ props.data.message }</p>
                </div>
                { props.data.closeIcon && 
                    <img src={ closeIcon } 
                        onClick={ () => appContext.setNotificationData({notificationStyle: styles.notification_wrapper}) } 
                    />
                }
            </div>
        </div>
    )
}
export default Notification