import { useContext } from 'react';

import styles from './modal_base.module.scss';
import { AppContext } from '../../pages/talents/TalentMain';


const ModalBase = () => {
    const appContext = useContext(AppContext)

    return (
        <div className={ styles.modal_base } onClick={() => appContext.setModalFlag(!appContext.modalFlag)}>
            <div className={ styles.stop_propagation } onClick={(event) => event.stopPropagation() }>
                {
                    appContext.modalContent
                }
            </div>
        </div>
    )
}
export default ModalBase