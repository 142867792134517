import PropTypes from 'prop-types'
import styles from './loading.module.scss'

export const Loading = ({
    label
}) => {

    return (
        <div className={ styles.loading_container }>
            <div className={ styles.loading_container_content }>
                <dotlottie-player 
                    src="https://lottie.host/244b74a0-5e5d-4b32-a88d-9ae4accecea0/Nccrao5GNn.json" 
                    background="transparent" 
                    speed="1" 
                    style={{width: '300px', height: '300px'}} 
                    loop autoplay>
                </dotlottie-player>
                <label>{label}</label>
            </div>
        </div>
    )
}

Loading.propTypes = {
    label: PropTypes.string
}

Loading.defaultProps = {
    label: 'Cargando información'
}