export const currentYear = new Date().getFullYear()
export const selectEmtpy = { id: 0, description: '' };
export const selectSearcherEmtpy = { id: 0, description: '', percentage: 0, categoryId: 0 };

export const FEEDBACK_DESC = [
	'Desempeño insatisfactorio',
	'No cumple con las expectativas',
	'Cumple con las expectativas',
	'Supera las expectativas',
	'Desempeño distinguido'
];

export const SKILLS = [
	{
		categoryId: 1001100001, 
		name: 'Técnicas', 
		title: 'Competencias Técnicas', 
		labelNewButton: 'Agregar otra', 
		placeholderNewSkill: 'Escribe una nueva competencia técnica'
	},
	{
		categoryId: 1001100002, 
		name: 'Personales', 
		title: 'Competencias Personales', 
		labelNewButton: 'Agregar otra',
		placeholderNewSkill: 'Escribe una nueva competencia personal'
	},
	{
		categoryId: 1001100003,
		name: 'Hábitos de trabajo',
		labelNewButton: 'Agregar otro',
		placeholderNewSkill: 'Escribe un nuevo hábito de trabajo'
	},
	{
		categoryId: 1001100004,
		name: 'Software',
		labelNewButton: 'Agregar otro',
		placeholderNewSkill: 'Escribe un nuevo software'
	}
]

export const MONTHS = [
	{ id: 1, optionName: "Enero"},
	{ id: 2, optionName: "Febrero"},
	{ id: 3, optionName: "Marzo"},
	{ id: 4, optionName: "Abril"},
	{ id: 5, optionName: "Mayo"},
	{ id: 6, optionName: "Junio"},
	{ id: 7, optionName: "Julio"},
	{ id: 8, optionName: "Agosto"},
	{ id: 9, optionName: "Septiembre"},
	{ id: 10, optionName: "Octubre"},
	{ id: 11, optionName: "Noviembre"},
	{ id: 12, optionName: "Diciembre"},
];

const year = {
	min: 1940,
	max: 2050
}

export const yearsOrder = {
	asc: 'asc',
	desc: 'desc',
	full: 'full'
}

export const yearsHardCode = order => {
	const years = [];
	let id = 1;
	if( order === yearsOrder.desc ) {
		for (let index = currentYear; index >= year.min; index--) {
			const element = {
				id: id++,
				optionName: `${index}`
			}
			years.push(element);
		}
	} else if ( order === yearsOrder.asc ) {
		for (let index = currentYear; index <= year.max; index++) {
			const element = {
				id: id++,
				optionName: `${index}`
			}
			years.push(element);
		}
	} else if ( order === yearsOrder.full ) {
		for (let index = year.min; index <= year.max; index++) {
			const element = {
				id: id++,
				optionName: `${index}`
			}
			years.push(element);
		}
	} else {
		console.log(order, 'not supported!');
	}
	return years;
}

export const softwareCatalogId=1001100004
export const hardSkillsCatalogId=1001100001
export const softSkillsCatalogId=1001100002