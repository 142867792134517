import { useContext } from 'react';
import styles from '../../talentInfo.module.scss';
import { AppContext } from '../../TalentMain';
import backArrowIcon from '../../../../../assets/icons/back_arrow.svg'
import feedbackStart from '../../../../../assets/icons/feedback_start.svg'
import { FEEDBACK_DESC } from '../../../../core/hardcode';
import { Skills } from './TalentSkills';

export const TalentEvaluation = ({
    review
}) => {

    const appContext = useContext(AppContext);

    const onClickBack = () => {
        appContext.setShowEvaluationDetail(false);
    }
    
    const renderBackButton = () => {
        return (
            <div className={ `${styles.backbutton} ${styles.padding_x_20}` } onClick = { () => onClickBack() }>
                <img src={ backArrowIcon } alt="" /><span>Ver evaluaciones</span>
            </div>
        )
    }

    const renderFeedback = feedback => {
        return (
            <div className={ styles.stars }>
                <img width='19px' height='18px' className={ feedback >= 1 ? styles.selected : '' } src={ feedbackStart } />
                <img width='19px' height='18px' className={ feedback >= 2 ? styles.selected : '' } src={ feedbackStart } />
                <img width='19px' height='18px' className={ feedback >= 3 ? styles.selected : '' } src={ feedbackStart } />
                <img width='19px' height='18px' className={ feedback >= 4 ? styles.selected : '' } src={ feedbackStart } />
                <img width='19px' height='18px' className={ feedback >= 5 ? styles.selected : '' } src={ feedbackStart } />
            </div>
        )
    }

    const renderRevieww = review => (
        <div className={ styles.review }>
            <div className={ styles.feedback }>
                { renderFeedback(review.rate) }
            </div>
        </div>
    );

    const renderDetailSection = (title, child) => (
        <div className={ `${styles.section} ${styles.padding_x_10}` }>
            <div className={ styles.title }>
                <div className={ styles.title_bar } />
                <h2>{ title }</h2>
            </div>
            <div className={ styles.content }>
                { child }
            </div>
        </div>
    )

    const renderSkillsDetail = (skills, title, comments) => (
        <div>
            { skills.length > 0 ? <Skills categories={ [0, 1001100001, 1001100002, 1001100004] } skills={ skills } /> : <p className={ styles.padding_y_16 }>{`No se agregaron ${title} en esta evaluación.`}</p> }
            { skills.length > 0 && <div className={ styles.pharagrap }>
                <div className={ styles.e_title }>
                    Comentarios sobre {title}
                </div>
                <p>{ !comments || comments === '' ? `No se agregaron comentarios sobre ${title}` : comments}</p>
            </div>}
        </div>
    )

    const renderSkillsSection = () => {

        return (
            <div className={ styles.sections }>
                { renderDetailSection( 'Fortalezas', renderSkillsDetail(review.strengths, 'Fortalezas', review.strengthsComment) ) }
                { renderDetailSection( 'Areas de Oportunidad', renderSkillsDetail(review.opportunities, 'Áreas de Oportunidad', review.opportunitiesComment) ) }
            </div>
        )
    }

    return (
        <div className={ styles.sections }>
            { renderBackButton() }
            <div className={ `${styles.section_review} ${styles.padding_left_30}` }>
                <div className={ styles.title }>
                    <div className={ `${styles.title_rewiew} ${styles.width_90}` }>
                        <div>
                            <span className={ styles.p_title }>Evaluador:</span> { review.authorName }
                        </div>
                        { review.period }
                    </div>
                </div>
                <div className={ styles.title }>
                    <span className={ styles.p_title }>Calificaci&oacute;n:</span> { FEEDBACK_DESC[review.rate - 1] }
                    { renderRevieww(review) }
                </div>
            </div>
            { renderSkillsSection() }
        </div>
    )
}
