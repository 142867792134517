import styles from '../../talentInfo.module.scss';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../TalentMain';
import { defaultStringEmpty } from '../../../../core/helper';
import { Evaluations } from '../evaluation/TalentEvaluations';
import { Academics } from './TalentAcademic';
import { WorkExperiences } from './TalentWorkExperience';
import { downloadCv, getSkillsCatalog, getTalent } from '../../../../api/talentInfoClient';
import { Languages } from './TalentLanguages';
import { Skills } from './TalentSkills';
import backArrowIcon from '../../../../../assets/icons/back_arrow.svg'
import feedbackStart from '../../../../../assets/icons/feedback_start.svg'
import talentPhotoDefault from '../../../../../assets/img/talent_image_default.svg';
import infoLeaderIcon from '../../../../../assets/icons/info_leader_icon.svg';
import infoAreaIcon from '../../../../../assets/icons/info_area_icon.svg';
import infoOrganizationIcon from '../../../../../assets/icons/info_organization_icon.svg';
import infoAntiquityIcon from '../../../../../assets/icons/info_antiquity_icon.svg';
import {
    FEEDBACK_DESC,
    softwareCatalogId,
    hardSkillsCatalogId,
    softSkillsCatalogId } from '../../../../core/hardcode';
import { Loading } from '../../../../components/loading/Loading';

export const TalentResume = () => {

    const appContext = useContext(AppContext);
    
    const [tal, setTal] = useState(appContext.talent);

    const fetchTalent = talentId => {
        appContext.setModalContent( <Loading /> );
        appContext.setModalFlag(true);
        getTalent(talentId).then((response) => {
            if( response.data && response.data.result ) {
                appContext.setTalent(response.data.result);
                setTal(response.data.result);
                appContext.setModalFlag(false);
            } else {
                appContext.setModalFlag(false);
            }
        }).catch(() => {
            appContext.setModalFlag(false);
        })
    }

    const fetchSkills = talentId => {
        getSkillsCatalog(talentId, 0, 100).then((response) => {
            if( response.status === 200 && response.data && response.data.content && Array.isArray(response.data.content) ) {
                let catTmp = [];
                response.data.content.map( r => {
                    catTmp.push({ tagId: r.id ,name: r.name, categoryId: r.categoryId});
                })
                appContext.setSkillsSuggestions(catTmp);
            }
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        fetchSkills( appContext.talentId );
        fetchTalent( appContext.talentId );
    }, [appContext.talentId])

    const onClickBack = () => {
        appContext.setTalent({});
        appContext.setSteper(0);
        appContext.setTab(1);
        appContext.setShowEvaluationDetail(false);
    }
    
    const renderBackButton = () => {
        return (
            <div className={ styles.backbutton } onClick = { () => onClickBack() }>
                <img src={ backArrowIcon } alt="" /><span>Regresar</span>
            </div>
        )
    }

    const renderFeedback = rate => {
        return (
            <div className={ styles.feedback }>
                <div className={ styles.stars }>
                    <img className={ rate >= 1 ? styles.selected : '' } src={ feedbackStart } />
                    <img className={ rate >= 2 ? styles.selected : '' } src={ feedbackStart } />
                    <img className={ rate >= 3 ? styles.selected : '' } src={ feedbackStart } />
                    <img className={ rate >= 4 ? styles.selected : '' } src={ feedbackStart } />
                    <img className={ rate >= 5 ? styles.selected : '' } src={ feedbackStart } />
                </div>
                <p>{ FEEDBACK_DESC[parseInt(rate)-1] }</p>
            </div>
        )
    }

    const renderInfoData = (icon, label, value) => (
        <div className={ styles.info }>
            <div className={ styles.container }>
                <img src={ icon } />
                <div className={ styles.title }>
                    <label>{ `${label}:` }</label>
                    <span>{ defaultStringEmpty(value) }</span>
                </div>
            </div>
            <div className={ styles.separator } />
        </div>
    )

    const onClickDownloadCV = () => {
        appContext.setModalContent( <Loading label={ 'Descargando CV' } /> );
        appContext.setModalFlag(true);
        downloadCv(tal.id).then((response) => {
            // window.open(URL.createObjectURL(response.data));
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${tal.firstName} ${tal.lastName}.pdf`.replaceAll(' ', '_').toLowerCase());
            document.body.appendChild(link);
            link.click();
            appContext.setModalFlag(false);
            // setTimeout(() => URL.revokeObjectURL( link.href ), 60000 );
        }).catch(error => {
            console.log(error);
            appContext.setModalFlag(false);
        })
    }

    const renderData = () => (
        <div className={ styles.data }>
            <div className={ styles.image }>
                <img className={ styles.photo } src={ tal.imgProfile ? tal.imgProfile : talentPhotoDefault } />
                <div className={ styles.button }>
                    <div className={ styles.container }>
                        <p className={ styles.title }>Sr</p>
                        <p className={ styles.download } onClick={ () => onClickDownloadCV() }>Descargar CV</p>
                    </div>
                </div>
            </div>
            <div>
                <p className={ styles.name }>{ `${tal.firstName} ${tal.lastName}` }</p>
                <p className={ styles.position }>{ tal.position }</p>
            </div>

            { renderFeedback(parseInt(tal.rating)) }

            { renderInfoData( infoLeaderIcon, 'Líder', tal.currentLeader) }
            { renderInfoData( infoAreaIcon, 'Área', tal.positionArea) }
            { renderInfoData( infoOrganizationIcon, 'Organización', tal.clientName) }
            { renderInfoData( infoAntiquityIcon, 'Antigüedad', tal.seniorityInTheJob) }
        </div>
    );

    const renderDetailSection = (title, child) => (
        <div className={ styles.section }>
            <div className={ styles.title }>
                <div className={ styles.title_bar } />
                <h2>{ title }</h2>
            </div>
            <div className={ styles.content }>
                { child ? child : <p>Información no porporcionada.</p> }
            </div>
        </div>
    )

    const renderResumeMain = () => {
        const hasLanguages = appContext.talent && appContext.talent.languages && appContext.talent.languages.length > 0;
        const hasSoftware = appContext.talent && appContext.talent.skills && appContext.talent.skills?.filter(s => s.categoryId === 1001100004).length > 0;
        // const hasSkills = appContext.talent && appContext.talent.skills && appContext.talent.skills?.filter(s => s.categoryId === 1001100001 || s.categoryId === 1001100003).length > 0;
        return (
            <div className={ `${styles.sections} ${styles.padding_x_10}` }>
                { renderDetailSection( `Acerca de ${tal.firstName}`, (<p>{ tal.description || 'Información no porporcionada' }</p>)) }
                { renderDetailSection( 'Idiomas', hasLanguages ? <Languages /> : null ) }
                { renderDetailSection( 'Software', hasSoftware ? <Skills categories={[softwareCatalogId]} /> : null ) }
                { renderDetailSection( 'Competencias',<Skills categories={[hardSkillsCatalogId, softSkillsCatalogId]} />) }
            </div>
        )
    };

    const onClickMenu = tab => {
        if( tab !== 2 ) {
            appContext.setShowEvaluationDetail(false);
        }
        appContext.setTab(tab);
    }

    return (
        <div className={ styles.wrapper }>
            { renderBackButton() }
            <div className={ styles.resume }>
                { renderData() }
                <div className={ styles.detail }>
                    <div className={ styles.menu }>
                        <span className={ appContext.tab === 1 ? styles.selected : '' } onClick={ () => onClickMenu(1) }>Resumen</span>
                        <span className={ appContext.tab === 2 ? styles.selected : '' } onClick={ () => onClickMenu(2) }>Evaluaciones</span>
                        <span className={ appContext.tab === 3 ? styles.selected : '' } onClick={ () => onClickMenu(3) }>Formaci&oacute;n Acad&eacute;mica</span>
                        <span className={ appContext.tab === 4 ? styles.selected : '' } onClick={ () => onClickMenu(4) }>Experiencia Laboral</span>
                    </div>
                    { appContext.tab === 1 && renderResumeMain() }
                    { appContext.tab === 2 && <Evaluations /> }
                    { appContext.tab === 3 && <Academics /> }
                    { appContext.tab === 4 && <WorkExperiences /> }
                </div>
            </div>
        </div>
    )
}
