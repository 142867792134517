import styles from './talentnotinfo.module.scss';
import talentListParamInvalid from '../../../assets/img/talent_list_invalid_param.png';

export const TalentNoInfo = () => {

  return (
    <div className={ styles.container }>
      <div className={ styles.container_image }>
        <img className={ styles.invert } width={ '300px' } height={ '60px' } src="https://www.mystratis.com/wp-content/themes/stratis/images/logo.svg" alt="Stratis" />
      </div>
      <h2>Informaci&oacute;n no disponible</h2>
      <div className={ styles.container_info }>
        <label>Lo sentimos, la informaci&oacute;n que intentas visualizar es confidencial y solo est&aacute; disponible para la persona a quien fue dirigida.</label>
        <label><b>Puedes intentarlo de nuevo desde la URL personalizada que recibiste directamente por parte de Stratis.</b></label>
        <label>Si presentas alg&uacute;n otro inconveniente para poder acceder, por favor, ponte en contacto con tu <b>Account Lead</b> para reportar el problema.</label>
      </div>
      <div className={ `${styles.container_image} ${styles.container_image_bottom}` }>
        <img src={ talentListParamInvalid } alt="Stratis" width={ '260px' } height={ '300px' } />
      </div>
    </div>
  )
}
