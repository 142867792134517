import { useContext, useEffect, useState } from 'react';
import { Searcher } from '../../../../components/searcher/Searcher';
import styles from '../../talentInfo.module.scss';
import { getTalents } from '../../../../api/talentInfoClient';
import { defaultStringEmpty, userData } from '../../../../core/helper';
import arrowView from '../../../../../assets/icons/table_detail_arrow.svg';
import talentStarReview from '../../../../../assets/icons/talent_score_star.svg';
import talentPhotoDefault from '../../../../../assets/img/talent_image_default.svg';
import talentListNotFound from '../../../../../assets/img/talent_list_not_found.png';
import talentListNotMatch from '../../../../../assets/img/talent_list_not_match.png';
import { AppContext } from '../../TalentMain';
import { Pagination } from '../../../../components/pagination/Pagination';
import { Loading } from '../../../../components/loading/Loading';

export const TalentList = () => {

  const appContext = useContext(AppContext);

  const title = 'Mis Servicios';
  const statusTalent = 1000900005;

  const [talents, setTalents] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const fetchTalents = (currentPage) => {
    appContext.setModalContent( <Loading /> );
    appContext.setModalFlag(true);
    if ( userData() ) {
      getTalents((userData()).userId, statusTalent, appContext.filter, currentPage, 6).then((response) => {
        if( response.status === 200 && response.data && response.data.content && Array.isArray(response.data.content) ) {
            setTalents(response.data.content)
            setTotalPages(response.data.totalPages);
            appContext.setModalFlag(false);
        } else {
          appContext.setModalFlag(false);
          setTalents([]);
          setTotalPages(0);
        }
      }).catch(error => {
        console.log('Error to fetch talents', error);
        setTalents([]);
        setTotalPages(0);
        appContext.setModalFlag(false);
      })
    }
  }

  useEffect(() => {
    fetchTalents(appContext.currentPage);
  }, []);

  const onClickDetail = talent => {
    appContext.setTalentId(talent.id);
    appContext.setSteper(1);

  }
  
  const onKeyDownSearch = ({key}) => {
    if ( key === 'Enter' || ( key === 'Backspace' & appContext.filter.length === 1) ) {
      onClickSearch();
    }
  }

  const onChangeSearch = ({target}) => {
    appContext.setFilter(target.value);
  }
  
  const onClickSearch = () => {
    appContext.setCurrentPage(0);
    fetchTalents(0);
  }

  const onClickBackPage = () => {
    if( appContext.currentPage > 0 ) {
      appContext.setCurrentPage(appContext.currentPage - 1);
      fetchTalents(appContext.currentPage - 1);
    }
  }

  const onClickForwardPage = cP => {
    if( appContext.currentPage + cP < totalPages ) {
      appContext.setCurrentPage(appContext.currentPage + 1);
      fetchTalents(appContext.currentPage + 1);
    }
  }

  const renderNotFound = () => {
    let title = '';
    let subtitles = [];
    let imgSrc = null;
    let cssParagraph = '';
    if( talents.length === 0 && appContext.filter === '' ) {
      title = 'No cuentas con servicios asignados.';
      subtitles.push('Si consideras que esto es un error, por favor, contacta a tu Account Lead');
      subtitles.push('para solucionar el problema o solicitar un nuevo servicio.');
      imgSrc = talentListNotFound;
      cssParagraph = styles.p_multiple;
    } else if (talents.length === 0 && appContext.filter !== '') {
      title = 'No existe ningún resultado para tu búsqueda.';
      subtitles.push('Inténtalo de nuevo revisando la ortografía o utilizando otro nombre.');
      imgSrc = talentListNotMatch;
      cssParagraph = styles.p_simple;
    }
    return (
      <div className={ styles.not_found }>
        <h2>{ title }</h2>
        {
          subtitles.map( (subtitle, index) => (
            <p className={ cssParagraph } key={ index }>{ subtitle }</p>
          ))
        }
        <img src={ imgSrc } />
      </div>
    )
  }

  const renderItems = () => talents.map( (talent, index) => (
    <tr key={ index } onClick={ () => onClickDetail(talent) }>
      <td className={ `${styles.first_child_border} ${styles.textright}` }>
        <div className={ styles.photo_padding }>
          <img className={ styles.photo } src={ talent.userImg ? talent.userImg : talentPhotoDefault } />
        </div>
      </td>
      <td className={ styles.name }>{ `${talent.firstName} ${talent.lastName}` }</td>
      <td>{ defaultStringEmpty(talent.position) }</td>
      <td className={ styles.textcenter }>
        <div className={ styles.review_container }>
          <div>{talent.rating}</div>
          <img src={ talentStarReview } />
        </div>
      </td>
      <td>{ defaultStringEmpty(talent.directLeaderName) }</td>
      <td>{ defaultStringEmpty(talent.positionArea) }</td>
      <td className={ styles.last_child_border }><img src={ arrowView } /></td>
    </tr>
  ) );

  const renderTable = () => (
    <table>
      <thead>
        <tr>
          <th className={ styles.first_child_border }></th>
          <th className={ styles.textleft }>Nombre</th>
          <th className={ styles.textleft }>Puesto</th>
          <th className={ styles.textcenter }>Calificaci&oacute;n</th>
          <th className={ styles.textleft }>L&iacute;der</th>
          <th className={ styles.textleft }>&Aacute;rea</th>
          <th className={ styles.last_child_border }></th>
        </tr>
      </thead>
      <tbody>
        { renderItems() }
      </tbody>
    </table>
  )

  return (
    <div className={ styles.wrapper }>
      <h2>{ title }</h2>
      <div className={ styles.width_40 }>
        <Searcher 
          name={'filter'} 
          value={ appContext.filter } 
          placeholder={ 'Buscar un Servicio por nombre' }
          onChange={ onChangeSearch }
          onKeyDown={ onKeyDownSearch }
          onClick={ () => onClickSearch() }
        />
      </div>
      { talents.length > 0 ? renderTable() : renderNotFound() }
      <Pagination
        totalElements={ talents.length }
        currentPage={ appContext.currentPage }
        totalPages={ totalPages }
        onClickBackPage={ onClickBackPage }
        onClickForwardPage={ onClickForwardPage }
      />
    </div>
  )
}
