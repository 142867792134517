import Axios from 'axios';

const logUrl = process.env.REACT_APP_BUGLOG_API;
const appName = process.env.REACT_APP_NAME;

export const saveLog = request => Axios.post(`${logUrl}error-logs/new`,{
    ...request,
    applicationName: appName,
    moduleName: appName,
    severity: "HIGH",
    status: "OPEN"
});