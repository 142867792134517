import { useContext } from 'react';
import styles from './evaluation.module.scss';
import { AppContext } from '../../../TalentMain';

export const CloseEvaluationModal = () => {

    const appContext = useContext(AppContext);

    const onClickConfirm = () => {
        appContext.setModalFlag(false);
        appContext.setSteper(1);
    }

    return (
        <div className={ styles.modal_cancel_evaluation }>
            <div className={ styles.content }>
                <label className={ styles.question }>¿Deseas salir de esta evaluaci&oacute;n?</label>
                <label className={ styles.instruction }>Si sales ahora, tu evaluaci&oacute;n no se guardará.</label>
                <div className={ styles.buttons }>
                    <div className={ styles.button_cancel } onClick={() => appContext.setModalFlag(false)}>
                        Volver a la evaluaci&oacute;n
                    </div>
                    <div className={ styles.button_confirm } onClick={() => onClickConfirm()}>
                        Salir de la evaluaci&oacute;n
                    </div>
                </div>
            </div>
        </div>
    )
}