import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { TalentNoInfo } from '../app/pages/invalidparam/TalentNoInfo';
import { TalentMain } from '../app/pages/talents/TalentMain';
import { ValidateParam } from '../app/pages/ValidateParam';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback, logError } from '../app/core/ErrorHandler';

export const AppRouter = () => {

    return (
        <ErrorBoundary FallbackComponent={ ErrorFallback } onError={logError}>
            <Routes>
                <Route path='/' element={ <Navigate to="/index.html" /> } />
                <Route path='/index.html' element={ <ValidateParam /> } />
                <Route path='/my-services' element={ <TalentMain /> } />
                <Route path='/invalid-data' element={ <TalentNoInfo /> } />
            </Routes>
        </ErrorBoundary>
    )
}
