import React, { useContext } from 'react';
import { AppContext } from '../../TalentMain'
import styles from '../../talentInfo.module.scss';

export const Languages = () => {

    const appContext = useContext(AppContext);

    const descriptions = [
        { id: 1, optionName: 'A1 - Principiante', description: 'Soy capaz de comprender y utilizar expresiones cotidianas de uso muy frecuente para presentarme y relacionarme a nivel básico.'},
        { id: 2, optionName: 'A2 - Básico', description: 'Soy capaz de comprender y comunicarme con frases y expresiones sencillas de uso cotidiano y personal'},
        { id: 3, optionName: 'B1 - Intermedio', description: 'Puedo comunicarme con frases breves, y comprender y redactar textos sencillos relacionados con mi área de experiencia.'},
        { id: 4, optionName: 'B2 - Intermedio Avanzado', description: 'Tengo la fluidez necesaria para comunicarme sin esfuerzo con hablantes nativos de manera oral y escrita.'},
        { id: 5, optionName: 'C1 - Avanzado', description: 'Puedo expresarme de forma fluida y espontánea, y comprender y redactar una amplia variedad de textos extensos y complejos relacionados con mi área de experiencia.'},
        { id: 6, optionName: 'C2 - Nativo', description: 'Mi nivel oral y escrito es prácticamente el de un nativo. Soy capaz de redactar y entender textos de cualquier tipo al igual que hablar de manera fluida y eficaz.'}
    ]

    const renderLanguages = () => appContext.talent.languages.map(( lang, index ) => {
        const description = descriptions.find( d => d.id === lang.langLevelId);
        return (
            <div key={index}>
                <span className={ styles.p_title }>{lang.languageName} { description.optionName }:</span> { description.description }
            </div>
        )
    })

    return (
        <div className={ styles.pharagrap }>
            { renderLanguages() }
        </div>
    )

}