import { useContext } from 'react';
import PropTypes from 'prop-types';
import styles from './evaluation.module.scss';
import { AppContext } from '../../../TalentMain';
import feedbackStart from '../../../../../../assets/icons/feedback_start.svg'
import { FEEDBACK_DESC } from '../../../../../core/hardcode';

const FEEDBACK_DETAIL = [
    'Selecciona la cantidad de estrellas que desees',
	'No cumple con sus objetivos y responsabilidades. Se requiere establecer acciones de mejora para potenciar de forma significativa su desempeño.',
	'Su desempeño es insuficiente. No logra los resultados esperados y necesita mejorar en algunos aspectos de su trabajo.',
	'Cumple satisfactoriamente y de manera continua con los objetivos y responsabilidades que se le han asignado.',
	'Cuenta con un alto desempeño y demuestra un alto nivel de competencia. Supera consistentemente los objetivos establecidos.',
	'Pocos logran ser reconocidos con esta calificación; es exclusiva de aquellos que destacan por su excelencia laboral. Cuenta con un nivel excepcional de competencia.'
];

export const EvaluationStars = ({
    name,
    value,
    onChange
}) => {

    const appContext = useContext(AppContext);

    const renderStars = rate => {
        return (
            <div className={ styles.feedback }>
                <div className={ styles.stars }>
                    <img className={ rate >= 1 ? styles.selected : '' } src={ feedbackStart } onClick={ () => onChange(name, 1) } />
                    <img className={ rate >= 2 ? styles.selected : '' } src={ feedbackStart } onClick={ () => onChange(name, 2) } />
                    <img className={ rate >= 3 ? styles.selected : '' } src={ feedbackStart } onClick={ () => onChange(name, 3) } />
                    <img className={ rate >= 4 ? styles.selected : '' } src={ feedbackStart } onClick={ () => onChange(name, 4) } />
                    <img className={ rate >= 5 ? styles.selected : '' } src={ feedbackStart } onClick={ () => onChange(name, 5) } />
                </div>
                <p className={ styles.description }>{ FEEDBACK_DESC[rate-1] }</p>
                <p className={ styles.instruction }>{ FEEDBACK_DETAIL[rate] }</p>
            </div> 
        )
    }

    return (
        <div className={ styles.form }>
            <div className={styles.star_section}>
                <p className={ styles.title }>{ `¿Cómo calificas el desempeño de ${appContext.talent.firstName}?` }</p>
                <p className={ styles.subtitle }>Puedes calificar de 1 a 5 estrellas, donde 5 representa un desempeño distinguido.</p>
                { renderStars(value) }
            </div>
        </div>
    )
}

EvaluationStars.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired
}