import React, { useContext } from 'react'
import { AppContext } from '../../TalentMain'
import styles from '../../talentInfo.module.scss';
import { SKILLS } from '../../../../core/hardcode';

export const Skills = ({
    categories
}) => {

    const appContext = useContext(AppContext);
    const renderCategoryCap = categoryId => (
        <div className={ styles.inline }>
            {
                appContext.talent.skills?.filter(s => s.categoryId === categoryId).map( (s, index) => (
                    <div key={ index } className={ styles.e_item }>{ s.name } ({ s.skillLevelPercentage}%)</div>
                ))
            }
            {
                appContext.talent.skills?.filter(s => s.categoryId === categoryId).length === 0 && (
                    <p className={ styles.no_info }>Información no proporcionada.</p>
                )
            }
        </div>
    )

    return (
        <div className={ styles.pharagrap }>
            {
                categories.length > 1 && categories.map( ( categoryId, index ) => (
                    <div key={ index } className={ styles.e_title }>
                        {SKILLS.find( c => c.categoryId === categoryId).name}
                        { renderCategoryCap(categoryId) }
                    </div>
                ))
            }
            { categories.length === 1 && renderCategoryCap(categories[0]) }
        </div>
    )
}
