import PropTypes from 'prop-types';
import styles from './textbutton.module.scss';
import { ReactComponent as AddIcon } from '../../../assets/icons/new_icon.svg'
import { useState } from 'react';

export const TextButton = ({
    label,
    value,
    placeholder,
    maxLength,
    onChange,
    onClick
}) => {

    const [counter, setCounter] = useState(maxLength);

    const handleOnChange = event => {
        setCounter(maxLength - event.target.value.length);
        onChange(event);
    }

    const handleOnClick = () => {
        if( value.length > 0 ) {
            setCounter(maxLength);
            onClick();
        }
    }

    return (
        <div className={ styles.full_container }>
            <div className={ styles.container }>
                <div className={ styles.input }>
                    <input type='text' autoComplete='off'
                        placeholder={placeholder}
                        value={value}
                        maxLength={maxLength}
                        onChange={ handleOnChange } />
                </div>
                <div className={ styles.button } onClick={ handleOnClick }>
                    <AddIcon />
                    { label }
                </div>
            </div>
            <label className={ counter === 0 ? styles.error_message : '' }>{ `${counter} caracteres restantes` }</label>
        </div>
    )
}

TextButton.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    maxLength: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired
}

TextButton.defaultProps = {
    maxLength: 50
}