import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../TalentMain";
import styles from '../../talentInfo.module.scss';
import { FEEDBACK_DESC, MONTHS, yearsHardCode, yearsOrder } from "../../../../core/hardcode";
import { defaultStringEmpty, serializeSelectValue, unsearializaDateString, userData } from "../../../../core/helper";
import feedbackStart from '../../../../../assets/icons/feedback_start.svg'
import arrowView from '../../../../../assets/icons/table_detail_arrow.svg';
import ReviewsNotFound from '../../../../../assets/img/reviews_not_found.png';
import { TalentEvaluation } from "./TalentEvaluation";
import { getFeedbacks } from "../../../../api/talentInfoClient";
import { Pagination } from "../../../../components/pagination/Pagination";
import { Loading } from "../../../../components/loading/Loading";

export const Evaluations = () => {
    const appContext = useContext(AppContext);

    const pageSize = 5;
    const [reviews, setReviews] = useState([]);
    const [reviewSelected, setReviewSelected] = useState();
    const [reviewsPagged, setReviewsPagged] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const setSelectDates = review => {
        const reviewTmp = { ...review };
        const yearHardCode = yearsHardCode(yearsOrder.desc);
        const reviewDate = unsearializaDateString(reviewTmp.date);
        const dayLength = reviewDate.day.length;
        const reviewDay = dayLength === 2 ? reviewDate.day : ( dayLength > 2 ? reviewDate.day.substring(0, 2) : reviewDate.day );
        const reviewMes = MONTHS.find( m => m.id === reviewDate.monthId );
        const reviewAnio = yearHardCode.find( y => y.optionName === reviewDate.yearDesc);
        reviewTmp.reviewDay = reviewDay;
        reviewTmp.reviewMes = serializeSelectValue(reviewMes);
        reviewTmp.reviewAnio = serializeSelectValue(reviewAnio);
        return reviewTmp;
    }

    const sorterDate = (date1, date2) => {
        let result = 0;
        if (date1 < date2) {
            result = 1
        } else if (date1 > date2) {
            result = -1
        }
        return result;
    }

    const fetchFeedbacks = () => {
        appContext.setModalContent( <Loading /> );
        appContext.setModalFlag(true);
        getFeedbacks((userData()).userId, appContext.talent.id).then( response => {
            if( response.status === 200 && response.data && Array.isArray(response.data) ) {
                let reviewsTmp = [];
                response.data.map( review => {
                    const reWithDates = setSelectDates(review);
                    reWithDates.period = `${reWithDates.reviewDay} de ${reWithDates.reviewMes.description} ${reWithDates.reviewAnio.description}`;
                    reviewsTmp.push(reWithDates);
                });
                reviewsTmp = reviewsTmp.sort((a, b) => sorterDate(a.date, b.date));
                setReviews(reviewsTmp);
                setTotalPages(Math.ceil(reviewsTmp.length/pageSize));
                setReviewsPagged(reviewsTmp.slice(0, pageSize));
                appContext.setModalFlag(false);
            } else {
                appContext.setModalFlag(false);
            }
        }).catch(error => {
            console.log(error);
            appContext.setModalFlag(false);
        })
    }

    useEffect(() => {
        fetchFeedbacks();
    }, []);

    const onClickDetail = review => {
        setReviewSelected(review);
        appContext.setShowEvaluationDetail(true);
    }

    const onClickBackPage = () => {
        if( currentPage > 0 ) {
            const prevPage = currentPage - 1;
            const start = prevPage * pageSize;
            const end = pageSize * currentPage;
            setReviewsPagged(reviews.slice(start, end));
            setCurrentPage(prevPage);
        }
    }
    
    const onClickForwardPage = cP => {
        if( currentPage + cP < totalPages ) {
            const start = (currentPage + cP) * pageSize;
            const nextPage = currentPage + cP + 1;
            const end = pageSize * nextPage;
            setReviewsPagged(reviews.slice(start, end));
            setCurrentPage(currentPage + 1);
        }
    }

    const renderFeedback = feedback => {
        return (
            <div className={ styles.stars }>
                <img width='19px' height='18px' className={ feedback >= 1 ? styles.selected : '' } src={ feedbackStart } />
                <img width='19px' height='18px' className={ feedback >= 2 ? styles.selected : '' } src={ feedbackStart } />
                <img width='19px' height='18px' className={ feedback >= 3 ? styles.selected : '' } src={ feedbackStart } />
                <img width='19px' height='18px' className={ feedback >= 4 ? styles.selected : '' } src={ feedbackStart } />
                <img width='19px' height='18px' className={ feedback >= 5 ? styles.selected : '' } src={ feedbackStart } />
            </div>
        )
    }

    const buildString = (listOfString, strDefault) => {
        if( Array.isArray(listOfString) ) {
            let lString = '';
            listOfString.map((s, index) => {
                lString = `${lString}${ index > 0 ? ', ' : ''}${s.name}`;
            })
            return lString === '' ? strDefault : lString;
        }
        return listOfString === '' ? strDefault : listOfString;
    }

    const renderRevieww = review => (
        <div className={ styles.review }>
            <div className={ styles.feedback }>
                <span className={ styles.p_title }>{ defaultStringEmpty(FEEDBACK_DESC[review.rate - 1]) }</span>
                { renderFeedback(review.rate) }
            </div>
            <div className={ styles.comments }>
                <div><span className={ styles.p_title }>Fortalezas:</span> { buildString(review.strengths, 'No se agregaron Fortalezas en esta evaluación.') }</div>
                <div><span className={ styles.p_title }>&Aacute;reas de oportunidad:</span> { buildString(review.opportunities, 'No se agregaron Áreas de oportunidad en esta evaluación.') }</div>
            </div>
        </div>
    );

    const renderDetailSection = () => reviewsPagged.map((review, index) => (
        <div key={index} className={ styles.review_card } onClick={ () => onClickDetail(review) }>
            <div className={ styles.section }>
                <div className={ styles.title }>
                    <div className={ styles.title_bar } />
                    <div className={ styles.title_rewiew }>
                        <h2>Evaluador: {review.authorName}</h2>
                        <span className={ styles.p_title }>{review.period}</span>
                    </div>
                </div>
                <div className={ styles.content }>
                    { renderRevieww(review) }
                </div>
            </div>
            <img className={ styles.arrow_view } src={ arrowView } />
        </div>
    ))

    const renderNotFound = () => (
        <div className={ styles.not_found }>
            <div>
                <p className={ styles.h2 }>Sin evaluaciones recibidas</p>
                <p className={ styles.h3 }>{appContext.talent.firstName} a&uacute;n no cuenta con ninguna evaluación de desempe&ntilde;o.</p>
            </div>
            <img src={ ReviewsNotFound } />
        </div>
    )

    const onClickNewFeedback = () => {
        appContext.setSteper(2);
    }

    const renderTitle = () => (
        <div className={ styles.feedback_title }>
            <h2>Historial de evaluaciones</h2>
            { userData().email !== appContext.talent.email && <div className={ styles.button } onClick={ () => onClickNewFeedback() }>Iniciar nueva evaluaci&oacute;n</div> }
        </div>
    )

    const renderFeedbacks = () => {
        const hasReviews = reviews.length > 0;
        return hasReviews ? (
            <div className={ styles.sections }>
                { renderDetailSection() }
                <Pagination
                    totalElements={ reviews.length }
                    currentPage={ currentPage }
                    totalPages={ totalPages }
                    onClickBackPage={ onClickBackPage }
                    onClickForwardPage={ onClickForwardPage }
                />
            </div>
        ) : renderNotFound();
    }

    const renderMain = () => (
        <div>
            { renderTitle() }
            { renderFeedbacks() }
        </div>
    )

    return appContext.showEvaluationDetail ? <TalentEvaluation review={ reviewSelected } /> : renderMain();
}
