import styles from './header.module.scss';
import { userData } from '../../core/helper';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../pages/talents/TalentMain';

export const Header = () => {

    const navigate = useNavigate();
    const appContext = useContext(AppContext);

    useEffect(() => {
        if( !userData() ) {
            navigate('/invalid-data');
        }
    }, [])

    const renderLogo = () => (
        <div>
            <a href="https://www.mystratis.com" target="_blank">
                <img src="https://www.mystratis.com/wp-content/themes/stratis/images/logo.svg" alt="Stratis" />
            </a>
        </div>
    )

    const renderUserInfo = () => userData() && (
        <div className={ styles.user_info }>
            { userData().name }
            <div className={ styles.user_info_separator }></div>
            { userData().clientName }
        </div>
    )

    const renderTalentData = () => (
        <div className={ styles.talent_info }>
            <div className={ styles.user_img }>
                <img className={ styles.photo } src={ appContext.talent.imgProfile } />
            </div>
            <div>
                <p className={ styles.talent_info_name }>{ `${appContext.talent.firstName} ${appContext.talent.lastName}` }</p>
                <p className={ styles.talent_info_position }>{ appContext.talent.position }</p>
            </div>
        </div>
    )

    const renderTalentLogo = () => (
        <div className={ styles.talent_info_logo }>
            <p className={ styles.talent_info_logo_title }>Evaluaci&oacute;n de desempe&ntilde;o</p>
            <div className={ styles.talent_info_logo_separator }></div>
            { renderLogo() }
        </div>
    )

    return (
        <div className={ appContext.steper === 2 ? styles.header_talent : styles.header }>
            { appContext.steper === 2 ? renderTalentData() : renderLogo() }
            { appContext.steper === 2 ? renderTalentLogo() : renderUserInfo() }
        </div>
    )
}
